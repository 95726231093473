<template>
  <div class="page-box page-padding">
    <div class="go-class">
      <el-button @click="()=> $router.go(-1)" class="plain-button">
        <div class="img-center"> <img src="@/assets/return.png" alt="返回" class="add-img"> <span>返回</span></div>
      </el-button>
    </div>
    <div class="class-content" v-loading='loading'>
      <h4>班级和课程时间</h4>
      <el-row>
        <el-col :span="10">
          <span>课程班级：</span><span>{{ readDetail.ClassName}}</span>
        </el-col>
        <el-col :span="14">
          <span>班级教练：</span><span>{{Array.isArray(readDetail.Teachers)&&readDetail.Teachers.map(item=>`${item.Name}(${item.TeacherType===1?'主教练':'助教'})`).join('/')}}</span>
        </el-col>
      </el-row>
      <el-row class="status-box">
        <el-col :span="10">
          <span>课程日期：</span>
          <span>
            {{ readDetail.StartTime && moment(readDetail.StartTime*1000).format('YYYY-MM-DD ') || '' }}
          </span>
        </el-col>
        <el-col :span="7">
          <span>课程时间：</span>
          <span>
            {{ (readDetail.StartTime && moment(readDetail.StartTime*1000).format('HH:mm'))+'至'+(readDetail.EndTime && moment(readDetail.EndTime*1000).format('HH:mm')) || '' }}
          </span>
          <span>
          </span>
        </el-col>
        <el-col :span="4" :offset="3">
          <div class="courseStatus">
            <span v-if="readDetail.Status===1" class="statusBox1">{{ readDetail.Status | courseStatus }}</span>
            <span v-if="readDetail.Status===2" class="statusBox2">{{ readDetail.Status | courseStatus }}</span>
            <span v-if="readDetail.Status===3" class="statusBox3">{{ readDetail.Status | courseStatus }}</span>
            <span v-if="readDetail.Status===5" class="statusBox5">{{ readDetail.Status | courseStatus }}</span>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <span>课程主题：</span><span>{{readDetail.Theme }}</span>
        </el-col>
        <el-col :span="14">
          <span>课程时长：</span>
          {{(readDetail.EndTime-readDetail.StartTime)/60}}分钟
        </el-col>
      </el-row>
      <h4 class="h-content">课程内容</h4>
      <el-row>
        <el-col>
          <template v-for="(item,index) in trainModList">
            <div :key="index" class="padding-div">
              <div>{{`${index+1+'、'} ${item.Name} (${item.Duration}分钟)`}}</div>
              <div style="white-space: pre-wrap;">{{item.Remarks}}</div>
            </div>
          </template>
        </el-col>
      </el-row>
      <h4>上课要求</h4>
      <el-row class="requirement">
        <el-col :span="10">
          <span>课程场地：</span><span>{{ readDetail.SiteName }}</span>
        </el-col>
        <el-col :span="14" class="clothing-box">
          <span>课程服装：</span>
          <template v-for="(item,index) in readDetail.Clothings">
            <div class="radio-box" :key="index">
              <span>{{`${item.Name}(${item.Color})`}}</span>
              <el-image class="image-box" :src="`${cosHttp}${item.ImageAddress}`" :preview-src-list="[`${cosHttp}${item.ImageAddress}`]" />
            </div>
          </template>
        </el-col>
      </el-row>
      <template v-if="readDetail.Status===5">
        <h4>取消原因</h4>
        <el-row>
          <el-col>
            <span style="white-space: pre-wrap;">{{readDetail.Reason}}</span>
          </el-col>
        </el-row>
      </template>
      <div v-if="newNoticeList.length>0">
        <h4 class="notice-btn">通知记录</h4>
        <template v-for="(item,index) in newNoticeList">
          <div :key="index">
            {{index+1}}、
            <span>{{item.CreateTime&&moment(item.CreateTime*1000).format('YYYY-MM-DD')}}</span>&emsp;
            <span>{{item.CreateTime&&moment(item.CreateTime*1000).format('HH:mm')}}</span>&emsp;
            <span>{{item.CreateUser}}</span>&emsp;
            <span>【{{item.Type|NoticeType}}】</span>
          </div>
        </template>
        <br>
      </div>
      <button class="notice-button" v-if="!Notice&&(readDetail.Status===1||readDetail.Status===5)" @click="noticeSend()">通知家长</button>
      <div v-if="Notice&&(readDetail.Status===1||readDetail.Status===5)">
        <div class="box">
          <el-image :src="`${QrCodeDate}`" :preview-src-list="[`${QrCodeDate}`]" class="QR-img">
          </el-image>
          <el-button type="primary" @click="getQrCode()" class="cope-link">二维码下载</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getClubCourseById, getNoticeListByCourseId, addCourseNotice, getNoticeQrCodeById } from '@/service/educational';
import moment from 'moment';
import vueQr from "vue-qr";
import { mapState } from "vuex";

export default {
  name: "ReadDetail",
  components: {
    vueQr,
  },
  data() {
    return {
      Id: "",
      loading: false,
      readDetail: {
      }, // 详情
      moment,
      trainModList: [], // 训练模块
      cosHttp: process.env.VUE_APP_COSHTTP,
      Notice: false, //消息未通知
      testUrl: "https://api.qx.flutterbbs.cn/qr/showCourse?type=coursemsg&id=",
      newNoticeList: [],
      QrCodeDate: '',
    }
  },
  computed: {
    // Duration() {
    //   let Duration = 0;
    //   Array.isArray(this.trainModList) && this.trainModList.forEach(item => {
    //     Duration += Number(item.Duration);
    //   })
    //   return Duration;
    // },

    ...mapState(['userInfo'])
  },
  created() {
  },
  async mounted() {
    this.Id = this.$route.query.id;
    await this.getClubCourseById();
    await this.getNoticeListByCourseId();
  },

  methods: {

    // refName ref名称   name下载文件名称
    getQrCode() {
      // let canvasBox = document.getElementById('canvasBoxOne');
      const iconUrl = this.QrCodeDate;
      const htmlElement = document.createElement('a');
      const event = new MouseEvent('click');
      htmlElement.download = `${this.userInfo.OrgName}-${this.readDetail.ClassName}`;
      htmlElement.href = iconUrl;
      htmlElement.dispatchEvent(event);
    },

    /**
     * 根据Id获取详情
     */
    async getClubCourseById() {
      let res = await getClubCourseById({ Id: this.Id })
      this.readDetail = res;
      this.Notice = res.Notice;
      this.trainModList = res.TrainMods;
    },

    /**
     * 根据课程Id获取历史通知
     */
    async getNoticeListByCourseId() {
      let res = await getNoticeListByCourseId({ CourseId: this.Id })
      this.newNoticeList = res;
      // this.newNoticeList[0].Id 最新的通知id（NoticeId）
      if (this.newNoticeList.length > 0) {
        await this.getNoticeQrCodeById(this.newNoticeList[0].Id);
      }
    },

    /**
     * 根据最新通知Id获取通知二维码
     */
    async getNoticeQrCodeById() {
      let res = await getNoticeQrCodeById({ NoticeId: this.newNoticeList[0].Id });
      this.QrCodeDate = res;
    },

    async noticeSend() {
      try {
        let res = await this.$confirm('是否确定发送通知？', '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确 定',
          cancelButtonText: '取 消',
          type: 'warning',
          center: true,
        })
        if (res != 'confirm') return;
        await addCourseNotice({
          ClubClassId: this.readDetail.ClassId,
          ClubCourseId: this.readDetail.Id,
          Type: this.readDetail.Status === 5 ? 3 : this.readDetail.NoticeId !== '0' ? this.readDetail.CreateTime === this.readDetail.UpdateTime ? 1 : 2 : 1
        });
        this.getClubCourseById();
        this.Notice = true;
        this.$message.success('发送成功');
        this.getNoticeListByCourseId();
      } catch (err) { };
    }
  }

}
</script>

<style lang="scss" scoped>
.el-row {
  margin: 10px 0;
}

.radio-box {
  display: flex;
  align-items: center;
  span {
    margin-right: 48px;
  }
}
.clothing-box {
  display: flex;
  align-items: center;
}

fieldset {
  border: none;
  border-top: 3px solid #ccc;
  legend {
    h4 {
      margin: 0 !important;
    }
  }
}
.box {
  // height: 198px;
  border: 1px solid #f8f9fa;
  text-align: center;
  background: #f8f9fa;
  padding: 16px 0;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .tag-copy {
    color: #8fcc80;
  }
  p {
    color: #6a757e;
    margin-bottom: 16px;
  }
  p:first-child {
    color: #21293a;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 8px;
  }
  a {
    color: #8fcc80;
  }
}
.notice-button {
  display: inline-block;
  width: 30%;
  height: 32px !important;
  padding: 6px 0;
  background: #8fcc80;
  border-radius: 2px;
  border: 1px solid #8fcc80;
  color: #fff;
  margin: 60px auto 0;
  cursor: pointer;
}
.padding-div {
  div {
    margin: 10px 0;
  }
}
.page {
  display: flex;
  flex-direction: column;
}
.class-content {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 24px 32px 32px;
  font-weight: 400;
  color: #6a757e;
  font-size: 14px;
  overflow-x: hidden;
  h4 {
    color: #21293a;
  }
  h4:first-child {
    margin-bottom: 16px;
  }
  .h-content {
    margin-top: 14px;
  }
}
.statusBox1,
.statusBox2,
.statusBox3,
.statusBox5,
.courseStatus {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  .statusBox1 {
    border: 1px solid #ff8900;
    color: #ff8900;
  }
  .statusBox2 {
    border: 1px solid #00b446;
    color: #00b446;
  }
  .statusBox3 {
    border: 1px solid #a6b4bf;
    color: #a6b4bf;
  }
  .statusBox5 {
    border: 1px solid #fe3a30;
    color: #fe3a30;
  }
}

.status-box {
  line-height: 50px;
}
.requirement {
  display: flex;
  align-items: center;
}
.QR-img {
  width: 180px;
  height: 180px;
  margin-bottom: 16px;
}
.notice-btn {
  margin-top: 16px;
}
</style>
